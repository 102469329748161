<template>
  <button-full
    @click.native="addToCart(product)"
    :disabled="isProductDisabled"
    data-testid="addToCart"
    class="btn btn-primary btn-buy"
  >
    <slot>{{ $t('Add to cart') }}</slot>
  </button-full>
</template>

<script>
import { formatProductMessages } from '@vue-storefront/core/filters/product-messages'
import { notifications, validateProduct } from '@vue-storefront/core/modules/cart/helpers'
import focusClean from 'theme/components/theme/directives/focusClean'
import ButtonFull from 'theme/components/theme/ButtonFull.vue'
import { mapGetters } from 'vuex'
import EventBus from '@vue-storefront/core/compatibility/plugins/event-bus'

export default {
  directives: { focusClean },
  components: { ButtonFull },
  props: {
    product: {
      required: true,
      type: Object
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    onAfterRemovedVariant () {
      this.$forceUpdate()
    },
    afterAddToCart (messages) {
      messages.forEach(message => {
        this.notifyUser(message)
      })
    },
    afterSuppliesProductAddToCart (messages) {
      this.$store.dispatch('supplies/setCartMessages', messages)
    },
    async addToCart (product) {
      try {
        this.$bus.$emit('cart-add-item', product) // needed for gtm
        const result = await this.$store.dispatch('cart/addItem', { productToAdd: product })

        const messages = []
        if (result) {
          messages.push({
            type: 'success',
            message: this.$t('Product has been added to the cart!'),
            action1: { label: this.$t('OK') },
            action2: null
          })
        }

        if (product.is_supplies_active) {
          this.$store.dispatch('supplies/openSupplies', { product })
        }
        if (product.is_supplies_active) {
          this.afterSuppliesProductAddToCart(messages)
        } else {
          this.afterAddToCart(messages)
        }
      } catch (message) {
        this.notifyUser(notifications.createNotification({ type: 'error', message }))
      }
    },
    notifyUser (notificationData) {
      this.$store.dispatch('notification/spawnNotification', notificationData, { root: true })
    }
  },
  computed: {
    ...mapGetters({
      isAddingToCart: 'cart/getIsAdding'
    }),
    isProductDisabled () {
      return this.disabled || formatProductMessages(this.product.errors) !== '' || this.isAddingToCart
    }
  },
  beforeMount () {
    this.$bus.$on('product-after-removevariant', this.onAfterRemovedVariant)
  },
  beforeDestroy () {
    this.$bus.$off('product-after-removevariant')
  }
}
</script>
